h1, h2, h3, h4, h5, h6 {
  word-wrap: break-word;
  word-break: break-word;
  font-family: Staatliches, sans-serif;
}

h1, h2 {
  /* TODO h1 and h2 should NOT have same size! */
  font-size: 40px;
}

a {
  word-wrap: break-word;
  word-break: break-word;
}

.highlighted {
  color: #e57231;
  font-weight: bold;
}

@media (max-width: 991px) {
  h1, h2 {
    /* TODO h1 and h2 should NOT have same size! */
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  h1, h2 {
    /* TODO h1 and h2 should NOT have same size! */
    font-size: 36px;
  }
}

@media (max-width: 479px) {
  h1, h2 {
    /* TODO h1 and h2 should NOT have same size! */
    font-size: 36px;
  }
}
