.app-wrapper {
  height: 100vh;
  display: flex;
  padding-top: 80px;
  padding-bottom: 0px;
  flex-direction: column;
}

.cookie-button {
  margin-top: 25px;
  margin-right: 25px;
}

/* Disbale cookie consent revoke showning constantly on bottom */
/* TODO We need to add this revoke option somewhere in the datenschutz section */
.cc-revoke {
  display: none;
}
