.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  /* Global font family! */
  font-family: Tahoma,Verdana,Segoe,sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
}

h3 {
  margin: 5px 0px 10px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

h4 {
  margin: 0px 0px 5px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin: 10px 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin: 10px 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #848998;
  font-size: 16px;
  line-height: 19px;
}

a {
  color: #56a0d3;
  text-decoration: none;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

.button {
  display: inline-block;
  padding: 4px 15px;
  background-color: #000;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.header {
  height: 100vh;
  padding-top: 122px;
  padding-bottom: 195px;
  background-color: #fff;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
  opacity: 1;
  color: #262b2f;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.bars-wrapper {
  background-color: #3e352a;
}

.bar {
  width: 14.285%;
  height: 15px;
  float: left;
  background-color: #262b2f;
}

.bar._2 {
  background-color: #9d7a59;
}

.bar._3 {
  background-color: #693e23;
}

.bar._4 {
  background-color: #636e81;
}

.bar._5 {
  background-color: #adc3cc;
}

.bar._6 {
  background-color: #6c5c50;
}

.bar._7 {
  background-color: #3e352a;
}

.logo-wrapper {
  margin-bottom: 147px;
}

.logo-icon {
  margin-top: -5px;
  margin-right: 9px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  float: left;
}

.logo-text {
  margin-top: 0px;
  margin-bottom: 0px;
  float: left;
  color: #e57231;
  font-size: 32px;
  line-height: 31px;
  font-weight: 500;
  text-shadow: none;
}

.main-subtitle {
  position: static;
  display: block;
  width: 60%;
  margin-bottom: 46px;
  margin-left: -20px;
  font-family: Staatliches, sans-serif;
  color: #e57231;
  font-size: 35px;
  line-height: 21px;
  letter-spacing: 0%;
  text-shadow: none;
  white-space: normal;
}

.app-button {
  margin-right: 14px;
  padding: 8px 13px;
  border-radius: 6px;
  background-color: #000;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.app-button:hover {
  background-color: #84a6ba;
}

.app-button.buy {
  margin-right: 0px;
  margin-left: 19px;
  background-color: #5d6779;
}

.app-button.buy:hover {
  background-color: #000;
}

.section {
  padding-top: 110px;
  padding-bottom: 110px;
}

.section.grey {
  background-color: rgba(255, 255, 255, 0.22);
  line-height: 22px;
}

.section.grey.buy {
  padding-top: 62px;
  padding-bottom: 62px;
}

.section.map {
  position: relative;
  padding-top: 184px;
  padding-bottom: 184px;
}

.section.footer {
  padding-top: 57px;
  padding-bottom: 57px;
  background-color: rgba(38, 43, 47, 0);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  color: #b5bbc8;
}

.section.sign-up {
  padding-top: 46px;
  padding-bottom: 46px;
  background-color: transparent;
  color: transparent;
}

.center-title {
  text-align: center;
}

.subtitle {
  margin-bottom: 72px;
  font-family: Staatliches, sans-serif;
  color: #e57231;
  font-size: 25px;
  line-height: 25px;
}

.subtitle.location {
  width: 30%;
  margin-bottom: 0px;
  color: #262b2f;
  text-align: center;
}

.center {
  text-align: center;
}

.line {
  display: inline-block;
  width: 40vw;
  height: 2px;
  margin-top: 42px;
  margin-bottom: 42px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #e1e4e8;
}

.feature-group.push-down {
  margin-bottom: 43px;
}

.push-down-column {
  padding-top: 0px;
}

.image-crop {
  overflow: hidden;
  height: 560px;
  margin-bottom: -110px;
}

.buy-title {
  margin-top: 6px;
  color: #5d6779;
  font-size: 33px;
  font-weight: 300;
}

.align-right {
  text-align: right;
}

.trans-test {
  display: block;
  margin: 7px;
  -webkit-transition: margin 500ms ease;
  transition: margin 500ms ease;
}

.trans-test:hover {
  margin: 16px;
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.location-title {
  width: 40%;
  margin-top: 10vh;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  font-family: Staatliches, sans-serif;
  color: #262b2f;
  font-weight: 400;
  text-align: center;
}

.map-icon {
  position: absolute;
  left: 43%;
  bottom: 39%;
}

.footer-left-col {
  padding-top: 8px;
}

.footer-right-col {
  display: block;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: right;
}

.footer-logo {
  width: 30%;
  margin-top: -11px;
  margin-right: 11px;
  float: left;
}

.footer-text {
  display: inline-block;
  margin-top: 0px;
  font-family: Staatliches, sans-serif;
  color: #fff;
  font-size: 200%;
  line-height: 25px;
}

.footer-link {
  padding-top: 9px;
  border-radius: 100%;
  background-color: #5d6779;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  text-align: center;
}

.footer-link:hover {
  background-color: #6c5c4f;
}

.field {
  width: 70%;
  height: 68px;
  margin-right: 2%;
  margin-bottom: 0px;
  padding: 12px 0px;
  float: none;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: inset 0 -4px 0 0 #262b2f;
  font-family: Staatliches, sans-serif;
  color: #5d6779;
  font-size: 26px;
  text-align: left;
}

.field:focus {
  box-shadow: inset 0 -4px 0 0 #d3d9de;
}

.form-button {
  width: 28%;
  padding: 24px 32px;
  border-radius: 5px;
  background-color: #e57231;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-family: Staatliches, sans-serif;
  color: #e8ebf1;
  font-size: 24px;
}

.form-button:hover {
  background-color: #d3d9de;
}

.form-button.submit-button-right {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
}

.form-wrapper {
  margin-bottom: 0px;
}

.success {
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  background-color: #d3d9de;
  text-align: center;
}

.heading {
  position: static;
  left: 3%;
  top: 28%;
  right: 1%;
  bottom: 0%;
  margin-right: auto;
  margin-left: auto;
  font-family: Staatliches, sans-serif;
  font-size: 100px;
  line-height: 100%;
  text-shadow: none;
  white-space: normal;
}

.div-block {
  display: block;
  width: 100vw;
  height: 80%;
  margin-top: 0px;
  padding-top: 3vh;
  background-color: rgba(252, 247, 247, 0);
  font-size: 100%;
}

.container {
  width: 970px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  text-align: left;
}

.container.entry-header {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 0px;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  background-color: hsla(0, 0%, 100%, 0.36);
}

.div-block-2 {
  padding-top: 2vh;
  padding-bottom: 0px;
  text-shadow: none;
}

.text-span {
  position: fixed;
  font-family: Staatliches, sans-serif;
  color: #b16726;
}

.icon {
  text-shadow: none;
}

.paragraph {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
  text-align: center;
}

.heading-2 {
  padding-top: 10px;
  text-align: center;
}

.paragraph-2 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
  text-align: center;
}

.heading-3 {
  text-align: center;
}

.heading-4 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 20px;
  text-align: center;
}

.paragraph-3 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
  font-size: 15px;
  text-align: left;
}

.image-2 {
  margin-left: 0px;
  padding-right: 0px;
}

.image-3 {
  position: static;
  display: inline-block;
  float: none;
  clear: none;
  text-align: left;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.button-2 {
  display: inline-block;
  width: 180px;
  margin-top: -19px;
  margin-bottom: 21px;
  margin-left: -20px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  border-radius: 10px;
  background-color: #e57231;
  opacity: 1;
  font-family: Staatliches, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  cursor: pointer;
}

.heading-5 {
  margin-top: 5vh;
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.paragraph-4 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
}

.container-2 {
  padding-top: 30px;
}

.heading-6 {
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.heading-7 {
  font-family: Staatliches, sans-serif;
  font-weight: 500;
  text-align: center;
}

.image-4 {
  display: inline-block;
  width: 100vw;
  margin-right: 11px;
  margin-left: 0px;
  padding-left: 0px;
  text-align: center;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-5 {
  width: 100%;
  margin-left: 0px;
  padding-left: 0px;
  text-align: center;
}

.image-6 {
  width: 100%;
  margin-left: 0px;
  text-align: center;
  -o-object-fit: contain;
  object-fit: contain;
}

.column {
  left: -1px;
  display: block;
  padding-left: 0px;
}

.block-quote {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 15px;
  text-align: left;
}

.heading-8 {
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.columns {
  margin-left: 0px;
}

.video {
  top: 20px;
  overflow: visible;
  width: 70vw;
  height: auto;
  margin-top: 4vh;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 651.3125px;
  -o-object-fit: contain;
  object-fit: contain;
}

.heading-9 {
  margin-top: 5vh;
  margin-bottom: 30px;
  font-family: Staatliches, sans-serif;
  font-size: 56px;
  line-height: 56px;
  font-weight: 400;
  text-align: center;
}

.paragraph-5 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.nav-link {
  font-family: Staatliches, sans-serif;
  font-size: 18px;
  text-shadow: none;
}

.nav-link.w--current {
  color: #262b2f;
}

.nav-link-2 {
  font-family: Staatliches, sans-serif;
  font-size: 18px;
  text-shadow: none;
}

.nav-link-2.w--current {
  color: #b16726;
}

.nav-link-3 {
  font-family: Staatliches, sans-serif;
  font-size: 18px;
  text-shadow: none;
}

.nav-link-3.current {
  color: #b16726;
}

.heading-10 {
  font-family: Staatliches, sans-serif;
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

.crowdfunding {
  background-color: rgba(88, 88, 88, 0.19);
}

.heading-11 {
  text-align: center;
}

.cta-section {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgba(95, 95, 95, 0.19);
}

.centered-container {
  margin-top: 10vh;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  text-align: center;
}

.container-3 {
  margin-top: 10vh;
}

.paragraph-6 {
  margin-top: 51px;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
}

.heading-12 {
  margin-top: 5vh;
  font-family: Staatliches, sans-serif;
  font-size: 56px;
  line-height: 56px;
  font-weight: 400;
}

.heading-13 {
  font-family: Staatliches, sans-serif;
  color: rgba(51, 51, 51, 0.73);
  font-size: 25px;
  font-weight: 400;
}

.bold-text {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
}

.bold-text-2 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}

.bold-text-3 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  color: #262b2f;
}

.bold-text-4 {
  color: #5d6779;
  text-decoration: none;
}

.heading-14 {
  font-family: Staatliches, sans-serif;
}

.paragraph-7 {
  position: relative;
  left: -178px;
  width: 30%;
  margin-left: auto;
  font-family: Staatliches, sans-serif;
  color: #262b2f;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.container-4 {
  display: block;
  padding-left: 0px;
}

.image-7 {
  display: inline-block;
  width: 40%;
  margin: 20px auto 20px 0px;
  -o-object-fit: contain;
  object-fit: contain;
}

.heading-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  font-family: Staatliches, sans-serif;
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
}

.link {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  font-family: Staatliches, sans-serif;
  color: #fff;
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
}

.div-block-3 {
  display: block;
  width: 45%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 8px;
}

.div-block-4 {
  display: block;
  width: 35%;
  margin-right: auto;
  margin-left: auto;
}

.div-block-5 {
  padding-top: 8px;
  text-align: center;
}

.heading-16 {
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.bold-text-5 {
  cursor: pointer;
}

.nav-link-4 {
  font-family: Staatliches, sans-serif;
  color: #262b2f;
  font-size: 18px;
  text-decoration: none;
  text-shadow: none;
}

.nav-link-4.w--current {
  color: #b16726;
}

.body {
  margin-top: 70px;
  padding-top: 0px;
}

.text-block {
  margin-top: 0px;
  text-align: center;
}

.heading-17 {
  margin-top: 40px;
  font-family: Staatliches, sans-serif;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.heading-18 {
  margin-top: 40px;
  font-family: Staatliches, sans-serif;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.heading-19 {
  margin-top: 100px;
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.heading-20 {
  margin-top: 40px;
  font-family: Staatliches, sans-serif;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.paragraph-8 {
  margin-top: 40px;
  text-align: center;
}

.heading-21 {
  margin-top: 40px;
  font-family: Staatliches, sans-serif;
  font-size: 250%;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: normal;
  text-indent: 0px;
}

.paragraph-9 {
  margin-top: 40px;
}

.bold-text-6 {
  display: inline-block;
  /* width: 62.372345620013vw; */
  margin-right: auto;
  margin-left: -20px;
  font-family: Staatliches, sans-serif;
}

.body-2 {
  display: block;
  overflow: visible;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  font-family: Staatliches, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.body-2:focus {
  margin-right: auto;
  margin-left: auto;
}

.container-5 {
  margin-top: 10vh;
}

.columns-2 {
  margin-top: 15vh;
}

.newsletter {
  margin-top: 10vh;
}

.container-7 {
  margin-top: 0vh;
}

.section-2 {
  background-color: #8a8a8a;
}

.columns-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10vh;
}

.section-3 {
  background-color: rgba(138, 137, 136, 0.26);
}

.column-2 {
  display: block;
}

.witten {
  background-color: rgba(88, 88, 88, 0.25);
}

.newsletter-wrapper {
  margin-top: 0vh;
  margin-bottom: 50px;
}

.footer-wrapper {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  min-height: 100%;
  background-color: #e57231;
}

.column-3 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navbar-2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-color: hsla(0, 0%, 100%, 0.59);
}

.section-4 {
  height: 50px;
  margin-top: 20px;
  background-color: #e57231;
}

.grid {
  grid-template-areas: "Area";
}

.image-8 {
  width: 30%;
}

.heading-22 {
  width: 30%;
  font-size: 56%;
}

.div-block-6 {
  display: block;
  width: 35%;
  margin-right: auto;
  margin-left: auto;
}

.container-8 {
  padding-right: 0px;
  padding-left: 0px;
  background-color: hsla(0, 0%, 100%, 0);
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-23 {
  font-family: Staatliches, sans-serif;
  text-align: center;
}

.heading-24 {
  margin-top: 21px;
  font-family: Staatliches, sans-serif;
  color: #e57231;
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.paragraph-10 {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.bold-text-7 {
  color: #262b2f;
}

.bold-text-8 {
  color: #e57231;
}

.bold-text-9 {
  color: #e57231;
  text-decoration: none;
}

.bold-text-10 {
  color: #e57231;
}

.link-2 {
  text-decoration: none;
}

.link-3 {
  color: #848998;
  text-decoration: none;
}

.link-4 {
  color: #848998;
  text-decoration: none;
}

.body-3 {
  color: #1a1b1f;
  font-size: 16px;
  line-height: 28px;
}

.not-found-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.not-found-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1a1b1f;
}

.not-found-content-wrap {
  margin-bottom: 20px;
}

.heading-jumbo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
  text-transform: none;
}

.paragraph-bigger {
  margin-bottom: 10px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.paragraph-bigger.cc-bigger-light {
  opacity: 0.6;
}

.go-back-home {
  padding: 12px 25px;
  border-radius: 0px;
  background-color: #1a1b1f;
  -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.go-back-home:hover {
  background-color: #32343a;
  color: #fff;
}

.go-back-home:active {
  background-color: #43464d;
}

.go-back-home.cc-white-button {
  padding: 16px 35px;
  background-color: #fff;
  color: #202020;
  font-size: 14px;
  line-height: 26px;
}

.go-back-home.cc-white-button:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.go-back-home.cc-white-button:active {
  background-color: hsla(0, 0%, 100%, 0.9);
}

.not-found-header {
  padding-top: 122px;
  padding-bottom: 195px;
  background-color: hsla(0, 0%, 100%, 0.19);
  color: #262b2f;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.not-found-container {
  padding-right: 0px;
  padding-left: 0px;
  background-color: hsla(0, 0%, 100%, 0);
  -o-object-fit: fill;
  object-fit: fill;
}

.primary-button {
  display: inline-block;
  width: 180px;
  margin-top: -19px;
  margin-bottom: 21px;
  margin-left: -20px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  border-radius: 10px;
  background-color: #e57231;
  opacity: 1;
  font-family: Staatliches, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  cursor: pointer;
}

.body-4 {
  padding-top: 0px;
}

.footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 120px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: red;
  text-align: center;
}

.footer-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.footer-image {
  width: 50px;
}

.footer-label {
  margin-top: 0px;
  margin-bottom: 15px;
}

.footer-link-2 {
  display: block;
  margin-bottom: 10px;
}

.body-5 {
  height: 100%;
}

.wrapper {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  min-height: 100%;
  background-color: transparent;
}

.footer-test-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.content-area {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;

  padding-bottom: 30px;
}

.main-footer {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #5d6779;
  background-color: #e57231;
}

.new-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 80px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.list {
  list-style-type: none;
}

.list-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}

.link-5 {
  font-family: Staatliches, sans-serif;
  color: #1b212b;
}

.div-block-7 {
  text-align: center;
}

.div-block-8 {
  text-align: center;
}

.content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
}

.media {
  margin: 35px 0px 40px;
}

.subtitle-wrapper {
  margin-top: 35px;
  margin-bottom: 40px;
}

.text-center {
  text-align: center;
}

.link-6 {
  text-decoration: none;
}

.first-media {
  margin-top: 1px;
  margin-bottom: 40px;
}

.div-block-9 {
  margin-top: 25px;
}

.heading-25 {
  background-color: transparent;
}

.main-header {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  color: #333;
}

.new-navbar {
  position: relative;
  min-height: 80px;
  float: none;
}

.new-navbar.navbar-default {
  background-color: #ffffffb8;
}

.new-navbar.navbar-left {
  float: left;
}

.navbar-header {
  float: left;
}

.navbar-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  float: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.image-9 {
  margin-top: 0px;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  text-align: left;
}

.container-fluid.entry-header {
  margin-bottom: 40px;
}

.navbar-right {
  float: right;
}

.list-item {
  float: left;
}

.list-item-2 {
  float: left;
}

.list-item-3 {
  float: left;
}

.menu-item {
  position: relative;
  display: block;
  float: left;
  font-family: Staatliches, sans-serif;
  font-size: 20px;
  text-align: center;
}

.navbar-nav {
  float: left;
  color: #000;
}

.navbar-nav.navbar-right {
  float: right;
}

.navbar-nav.navbar-right.header-navbar:hover {
  color: #e57231;
}

.navbar-nav.navbar-left {
  float: none;
  clear: none;
}

.navbar-link {
  position: relative;
  display: block;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  padding: 26px 0px 10px;
  font-family: Oswald, sans-serif;
  color: #262b2f;
  font-size: 18px;
  font-weight: 400;
}

.navbar-link.header-nav-link {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
}

.navbar-link.header-nav-link:hover {
  font-weight: 700;
}

.navbar-link.header-nav-link.current {
  border-bottom: 3px solid #e57231;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.navbar-link.footer-nav-link {
  color: #262b2f;
  font-size: 16px;
  font-weight: 400;
}

.navbar-link.footer-nav-link:hover {
  color: #fff;
}

.text-block-2 {
  margin-left: 10px;
  font-family: Staatliches, sans-serif;
  color: #e57231;
  font-size: 45px;
  font-weight: 400;
}

.heading-26 {
  font-family: Varela, sans-serif;
}

.home-buddy-hand {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url('/images/hand.png');
  background-position: 100% 100%;
  background-size: 25vw;
  background-repeat: no-repeat;
  opacity: 1;
  -webkit-transition: opacity 125ms ease;
  transition: opacity 125ms ease;
}

.heading-27 {
  font-size: 40px;
}

.text-block-2-copy {
  font-family: Oswald, sans-serif;
  color: #e57231;
  font-size: 30px;
  line-height: 20px;
  font-weight: 500;
}

.heading-27-copy {
  font-size: 40px;
}

.navbar-toggle {
  display: none;
}

.html-embed {
  display: block;
}

.welcome-wrapper {
  height: 100vh;
  background-color: #f12d2d;
  background-image: url('/images/hand.png');
  background-position: 100% 100%;
  background-size: 125px;
  background-attachment: scroll;
}

.home-image-holder {
  position: fixed;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 100vw;
  height: 100vh;
  background-image: url('/images/hand.png');
  background-position: 100% 100%;
  background-size: 25vw;
  background-repeat: no-repeat;
  opacity: 1;
}

.home-heading {
  font-family: Staatliches, sans-serif;
  font-size: 100px;
  line-height: 100%;
  font-weight: 400;
}

.div-block-10 {
  float: none;
}

.footer-me {
  float: left;
}

.footer-nav-wrapper {
  float: left;
}

.section-heading {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: Staatliches, sans-serif;
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  text-transform: none;
}

.home-section {
  margin-top: 80px;
  margin-bottom: 30px;
}

.section-paragraph {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
}

.section-paragraph.small-note {
  font-size: 12px;
}

.success-message {
  background-color: #ddd;
}

.form-block {
  margin-top: 20px;
}

.field-label {
  margin-top: 15px;
}

html.w-mod-js *[data-ix="new-interaction"] {
  display: inline-block;
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

html.w-mod-js *[data-ix="new-interaction-2"] {
  display: inline-block;
  opacity: 0.37;
}

html.w-mod-js *[data-ix="new-interaction-3"] {
  display: inline;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

@media (max-width: 991px) {
  .header {
    background-color: hsla(0, 0%, 96.7%, 0.76);
    /* background-image: url('/images/hand.png'); */
    background-position: 100% 100%;
    background-size: 35%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .main-subtitle {
    margin-left: 0px;
    line-height: 35px;
    font-style: normal;
    text-align: left;
    text-decoration: none;
  }
  .app-button.buy {
    margin-left: 10px;
    padding: 5px 9px;
  }
  .subtitle {
    margin-bottom: 52px;
  }
  .push-down-column {
    padding-top: 0px;
  }
  .image-crop {
    height: 605px;
  }
  .buy-title {
    font-size: 26px;
    line-height: 26px;
  }
  .location-title {
    display: block;
    width: 60%;
    margin-top: 10%;
  }
  .footer-logo {
    margin-top: 0px;
  }
  .footer-text {
    display: block;
    margin-top: 0px;
    margin-left: 140px;
    font-size: 200%;
  }
  .image {
    padding-top: 10vw;
  }
  .div-block {
    padding-top: 0vh;
  }
  .container {
    width: 770px;
  }
  .navbar {
    background-color: hsla(30, 0%, 100%, 0.53);
  }
  .text-span {
    font-family: Staatliches, sans-serif;
    color: #c2722c;
    line-height: 33px;
    font-weight: 400;
  }
  .icon {
    text-shadow: none;
  }
  .paragraph {
    font-size: 15px;
  }
  .heading-2 {
    font-size: 20px;
  }
  .paragraph-2 {
    font-size: 15px;
  }
  .heading-3 {
    font-size: 20px;
  }
  .button-2 {
    margin-left: 0px;
  }
  .crowdfunding {
    background-color: hsla(233, 0%, 40%, 0.21);
  }
  .paragraph-7 {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
  }
  .container-4 {
    padding-left: 0px;
  }
  .image-7 {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .heading-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
  }
  .div-block-4 {
    width: 50%;
  }
  .bold-text-6 {
    margin-left: auto;
  }
  .div-block-6 {
    width: 50%;
  }
  .container-8 {
    background-color: hsla(0, 0%, 89.3%, 0);
  }
  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }
  .go-back-home {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .not-found-header {
    background-color: hsla(0, 0%, 96.7%, 0.76);
  }
  .not-found-container {
    background-color: hsla(0, 0%, 89.3%, 0);
  }
  .primary-button {
    margin-left: 0px;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .heading-27 {
    font-size: 40px;
  }
  .heading-27-copy {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 39px 10px 405px;
    background-position: 100% 100%;
    background-size: 40%;
  }
  .logo-wrapper {
    margin-bottom: 37px;
  }
  .main-subtitle {
    width: 84%;
    margin-bottom: 41px;
    font-size: 200%;
  }
  .app-button {
    margin-bottom: 14px;
  }
  .app-button.buy {
    margin-right: 13px;
    margin-left: 13px;
  }
  .section {
    padding-top: 42px;
    padding-bottom: 42px;
    text-align: center;
  }
  .section.grey.buy {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .section.map {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  .section.footer {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .section.sign-up {
    padding-right: 10px;
    padding-left: 10px;
  }
  .center-title {
    padding-right: 10px;
    padding-left: 10px;
  }
  .subtitle.location {
    width: auto;
    margin-right: 20%;
    margin-bottom: 27px;
    margin-left: 20%;
  }
  .line {
    display: block;
    margin: 32px auto;
  }
  .image-crop {
    height: auto;
    margin-bottom: 0px;
  }
  .buy-title {
    margin-bottom: 31px;
  }
  .align-right {
    text-align: center;
  }
  .map-icon {
    position: static;
  }
  .footer-left-col {
    display: inline;
    margin-right: auto;
    margin-left: auto;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .footer-right-col {
    margin-top: 13px;
    text-align: center;
  }
  .footer-logo {
    display: block;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 8px;
    float: none;
  }
  .footer-text {
    margin-top: -56px;
    margin-bottom: 22px;
    margin-left: 111px;
  }
  .image {
    width: 269px;
  }
  .field {
    width: 50%;
    font-size: 22px;
  }
  .form-button {
    width: 48%;
  }
  .heading {
    font-size: 90px;
  }
  .div-block {
    padding-top: 10vh;
  }
  .container {
    max-width: 100vw;
  }
  .icon {
    position: fixed;
    left: auto;
    top: 2vh;
    right: 10vw;
    bottom: auto;
    text-shadow: none;
  }
  .button-2 {
    margin-top: 0px;
  }
  .cta-section {
    padding: 40px 20px;
  }
  .paragraph-7 {
    margin-right: auto;
    margin-left: 214px;
  }
  .container-4 {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
  }
  .image-7 {
    display: block;
    margin-top: 40px;
  }
  .heading-15 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .link {
    display: block;
  }
  .div-block-4 {
    display: inline-block;
    width: 100%;
  }
  .div-block-5 {
    display: inline-block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .paragraph-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .container-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .columns-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .footer-wrapper {
    margin-bottom: 0px;
  }
  .column-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .not-found-page-wrap {
    padding: 15px;
  }
  .not-found-wrap {
    padding: 30px;
  }
  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }
  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }
  .not-found-header {
    padding: 100px 10px 405px;
    background-image: none;
  }
  .primary-button {
    margin-top: 0px;
  }
  .wrapper {
    margin-bottom: 0px;
  }
  .content-wrapper {
    display: block;
  }
  .column-4 {
    padding-left: 25px;
  }
  .main-navigation.active {
    display: block;
  }
  .heading-27 {
    font-size: 36px;
  }
  .heading-27-copy {
    font-size: 36px;
  }
}

@media (max-width: 479px) {
  .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }

  .header {
    padding-bottom: 506px;
    background-position: 100% 100%;
    background-size: auto 40vh;
  }
  .main-subtitle {
    font-size: 10vw;
    line-height: 100%;
  }
  .section.sign-up {
    padding-top: 39px;
    padding-bottom: 39px;
  }
  .location-title {
    width: 60%;
    margin-top: 10%;
  }
  .footer-left-col {
    display: block;
    margin-top: -50px;
    padding-top: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .footer-right-col {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .footer-text {
    margin-top: -42px;
  }
  .field {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 15px;
    text-align: left;
  }
  .form-button {
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .heading {
    font-size: 14vw;
    line-height: 100%;
  }
  .div-block {
    padding-top: 7vh;
  }
  .icon {
    text-shadow: none;
  }
  .button-2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .paragraph-7 {
    left: 0px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .container-4 {
    display: inline-block;
  }
  .image-7 {
    display: block;
    width: 70%;
    margin: 30px auto;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .heading-15 {
    width: 60%;
  }
  .link {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-decoration: underline;
  }
  .div-block-4 {
    width: 200%;
    margin-right: 8px;
    margin-left: -50px;
  }
  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .heading-20 {
    font-size: 250%;
    font-weight: 400;
  }
  .paragraph-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .bold-text-6 {
    margin-top: 0px;
  }
  .columns-3 {
    display: block;
  }
  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }
  .not-found-header {
    padding-bottom: 506px;
    background-position: 100% 100%;
    background-size: 50%;
  }
  .primary-button {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
  }
  .footer-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-label {
    margin-top: 15px;
  }
  .subtitle-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .column-4 {
    padding-left: 10px;
  }
  .navbar-header {
    float: none;
  }
  .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
  .main-navigation {
    display: none;
  }
  .menu-item {
    position: static;
    margin-right: auto;
    margin-left: auto;
    float: none;
    border-top: 1px solid #d6d6d6;
  }
  .menu-item.footer-item {
    border-top-style: none;
  }
  .menu-item.footer-item.inline-menu-item {
    display: inline-block;
  }
  .navbar-nav.navbar-right.header-navbar {
    float: none;
  }
  .navbar-nav.navbar-right.inline-footer-menu {
    float: right;
  }
  .navbar-nav.navbar-right.center-nav {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .navbar-link.header-nav-link {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  .navbar-link.header-nav-link.current {
    display: inline-block;
  }
  .navbar-link.footer-nav-link {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 16px;
    padding-left: 16px;
    text-align: left;
  }
  .heading-27 {
    max-width: 270vw;
  }
  .navbar-toggle {
    position: absolute;
    top: 25px;
    right: 25px;
    display: block;
    font-family: Oswald, sans-serif;
    font-size: 30px;
    color: #262b2f;
    font-weight: 500;
    cursor: pointer;
  }
  .footer-nav-wrapper {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .section-heading {
    font-family: Staatliches, sans-serif;
    font-size: 40px;
    text-align: center;
  }
  .section-paragraph {
    text-align: left;
  }
}
