.x-heading-container {
  margin-top: 0px !important;
  /* This is really just our padding to the header. It does not need to be 80px
   * anymore since we put an invisible ::before element before each section to
   * ensure the spacing from the top (because there is the header)
   */
  padding-top: 45px;

  /* Hot-fix for padding of startnext button */
  padding-bottom: 20px;
}

.home-fade-image {
  pointer-events: none;
}

/** Hot fix for padding on title under crowdfunding video */
.x-cf-heading {
  padding-top: 25px;
}

.supporters-container {
  text-align: center;
}

.supporter-image {
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.live-cooking-teaser {
  width: 60vw;
}

.live-cooking-teaser img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .supporter-image {
    width: 50vw
  }
}

@media (max-width: 767px) {
  .supporter-image {
    width: 50vw;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 479px) {
  .supporter-image {
    width: 50vw;
    padding-left: 0px;
    padding-right: 0px;
  }

  .live-cooking-teaser {
    width: 100%;
  }
}
