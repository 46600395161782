.team-member .name {
 font-size: 40px;
 font-family: Staatliches,sans-serif;
 padding-bottom: 15px;
}

.team-member .role {
 font-size: 18px;
 font-family: Staatliches,sans-serif;

 padding-top: 10px;
}