* {
  margin: 0;
  padding: 0;
}

/*
 * Puts an invisible element before the section/header where this class is used.
 * This ensured the correct margin (80px) so content is not hidden by the navbar.
 */
section::before {
  display: block;
  content: " ";
  margin-top: -80px;
  height: 80px;
  visibility: hidden;
  pointer-events: none;
}

.home-header {
  height: calc(100vh - 80px);
  /*background-image: url('/images/home-background-1.png');*/
  /*background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)),
    url('/images/home-background-1.png');*/
  background-color: #e57231;
  /* flex-box thing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-size: cover;
  background-position: center;

  text-align: center;

  color: white;
}

.home-header h1 {
  font-size: 4em;
  font-weight: normal;

  /*text-shadow: 0 0 12px #515151; /* Looks good on orange and better readable images */
}

.home-header .part {
  display: flex;
  width: 100%;
}

.home-header .left {
  background-color: #e57231;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 0;
}

.header-text {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 30px;
  font-family: Roboto, sans-serif;
}

.header-text p {
  font-size: 1em;
  color: white;
}

.header-text .title {
  font-size: 1.1em;
  color: white;
  font-family: Staatliches, sans-serif;
  padding-bottom: 2.5px;
}

.home-header .right {
  background-size: cover;
  background-position: center;
  flex: 1;

  transition: background 0.6s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 60px;
}

.background-image-0 {
  background-image: url('/images/background_4.jpeg');
}
.background-image-1{
  background-image: url('/images/background_2.jpeg');
}
.background-image-2 {
  background-image: url('/images/background_1.jpeg');
}

.home-header p {
  margin-bottom: 10px;
}

.home-header h1,
.home-header h2,
.home-header h3,
.home-header h4,
.home-header h5,
.home-header h6,
.home-header p {
  color: white;
}

.home-header .button {
  font-family: Staatliches, sans-serif;
  color: white;
  background-color: #e57231;

  font-size: 1em;
  padding: 15px;
  margin: 15px;
}

.home-header-container {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

#home-down {
 position: absolute;
 bottom: 0;
 right: 0;
 left: 0;
 padding-bottom: 5px;
}

#home-down h3 {
  color: #e57231;
  margin: 0px;
  padding: 0px;
  font-size: 32px;
  font-weight: bold;
}
#home-down p {
  margin: 0px;
}

#arrow-down {
  font-size: 2em;
}

#home-down:hover {
 cursor: pointer;
 opacity: 0.8;
}

@media (max-height: 600px) {
  .home-header {
    padding-top: 5px;
  }
}

.feature-desc-center {
  /* Only set display flex and center for non-mobile sizes */
}

.mobile-hidden {
  display: none;
}

.mobile-only {
  display: inline-block;
}


/* App Store Buttons */
.app-store-container {
  text-align: center;
  margin-top: 30px;
  width: 100%;
}

.app-store-button {
  height: 55px;
  width: 180px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* Responsive changes */

/*
 * NOTE: This is already MOBILE FIRST css!
 */

 @media (min-width: 480px) {
   .home-header h1 {
    font-size: 7em;
    line-height: 1em;
   }

   .home-header .button {
     font-size: 25px;
     padding: 25px;
     margin: 50px;
   }

   /* App Store Buttons */
  .app-store-container {
    margin-top: 5px;
  }

  .app-store-button {
    height: 55px;
    width: 180px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
 }

@media (min-width: 768px) {
  .home-header-container {
    max-width: 700px;
  }

  .home-header {
    justify-content: center;
    padding-top: 0;
  }

  .feature-desc-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .home-header-container {
    max-width: 900px;
  }

  #home-down {
   padding-bottom: 15px;
  }

  .mobile-hidden {
    display: inline-block;
  }

  .mobile-only {
    display: none;
  }

  .home-header {
    height: calc(100vh - 80px);
    /*background-image: url('/images/home-background-1.png');*/
    /*background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)),
      url('/images/home-background-1.png');*/
    background-color: #e57231;
    /* flex-box thing */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-size: cover;
    background-position: center;
  
    text-align: center;
  
    color: white;
  }

  .home-header .part {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .home-header .left {
    background-color: #e57231;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  
    padding-bottom: 10vh;
    flex: 1;
  }

  .home-header h1 {
    font-size: 8em;
    font-weight: normal;
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 120px;

    /*text-shadow: 0 0 12px #515151; /* Looks good on orange and better readable images */
  }

  .header-text p {
    font-size: 1.7em;
    line-height: 1.1em;
    color: white;
  }
  
  .header-text .title {
    font-size: 2em;
    line-height: 1.1em;
    color: white;
    font-family: Staatliches, sans-serif;
    padding-bottom: 5px;
  }
}

@media (min-width: 1400px) {
  .home-header-container {
    max-width: 1100px;
  }

  .home-header h1 {
    font-size: 10em;
    line-height: 1.1em;
  }
  .home-header p {
    font-size: 1.5em;
    line-height: 25px;
  }
}
